.background-primary {
    background: var(--primary);
}

.background-secondary {
    background: var(--secondary);
}

.background-tertiary {
    background: var(--tertiary);
}

.background-forthtiary {
    background: var(--forthtiary);
}

.background-fivetiary {
    background: var(--fivetiary);
}

.background-sixtiary {
    background: var(--sixtiary);
}

.background-seventiary {
    background: var(--seventiary);
}

.background-success {
    background: var(--success);
}

.background-warning {
    background: var(--warning);
}

.background-danger {
    background: var(--danger);
}

.background-info {
    background: var(--info);
}

.background-dark {
    background: var(--dark);
}