.wselect {
  box-shadow: var(--shadow-input) !important;
  border-radius: 6px;
  color: var(--gray-700);
  font-size: 15px;
  border: 2px solid transparent !important;
  cursor: pointer;
}

.flexContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom-color: #ECECEC;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  padding-bottom: 5px;
}

.flexRowWrap {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

.trashDeleteButton {
  cursor: pointer;
}

.trashDeleteButton:hover {
  filter: brightness(0) saturate(100%) invert(39%) sepia(90%) saturate(1707%) hue-rotate(328deg) brightness(106%) contrast(88%);
}

.editButton {
  cursor: pointer;
  margin-right: 16px;
}

.editButton:hover {
  filter: brightness(0) saturate(100%) invert(50%) sepia(98%) saturate(930%) hue-rotate(180deg) brightness(98%) contrast(95%);
}
