.logo-weyzo {
    width: 25px;
    position: relative;
    left: -42px
}

.spin {
    width: 60px;
    animation: spin 2s linear infinite
}

@keyframes spin {
    100% {
        transform: rotate(360deg)
    }
}