.container-users {
    display: flex; 
    align-items: center; 
    justify-content: space-between; 
    margin-bottom: 15px;
}

.inputs-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.badge {
    padding: 8px 16px;
    background-color: var(--primary-light);
    border-radius: 8px;
}
