.loginContainer {
  width: 450px;
  margin: 30px auto;
}

@media only screen and (max-width: 768px) {
  .loginContainer {
    width: 300px;
    margin: 30px auto;
  }
}

.link-button {
  padding-left: 0;
}

.Input {
  box-shadow: var(--shadow-input) !important;
  border-radius: 6px;
  color: var(--gray-700);
  font-size: 15px;
  border: 2px solid transparent !important;
  height: 40px;
}

.Input:focus {
  border: 2px solid var(--secondary-dark) !important;
  box-shadow: var(--shadow-sm) !important;
}
