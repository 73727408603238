.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 22px;
    min-width: 40px;
}
.switch input {
    opacity: 0;
    width: 0;
    height: 0
}
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    -webkit-transition: .4s;
    border-radius: 34px
}
.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: .4s;
    -webkit-transition: .4s;
    border-radius: 50%
}
.input:checked + .slider {
    background-color: #4ACE67;
}
.input:focus + .slider {
    box-shadow: 0 0 1px #4ACE67
}
.input:checked + .slider:before {
    transform: translateX(16px);
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px)
}
.slider.round {
    border-radius: 34px;
}
.slider.round:before {
    border-radius: 50%
}