.inputSearch {
  width: 60%;
  border-radius: 6px;
  border: none;
  background-color: var(--tertiary);
}

.inputSearch input {
  background-color: var(--tertiary) !important;
  margin-left: 6px;
}

.limit-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #f2eaff;
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 10px;
  cursor: auto;
}
.limit-percent-number {
  margin-right: 40px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.limit-container p {
  margin-top: 0;
  margin-bottom: 0;
}
.limit-container img {
  margin-right: 10px;
}
.limit-leads {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #4a5568;
}
.limit-leads span {
  color: #8847e6;
}
.blurred {
  color: transparent;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
