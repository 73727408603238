.switch-group {
    display: flex;
    flex-direction: row;
}
.switch-group p {
    margin-left: 16px;
    margin-right: 60px
}

.profileRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ECECEC;
    padding-bottom: 5px;
    margin-bottom: 15px;
}

.flexRow {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
  
.flexColumn {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.centeredFlex {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}
