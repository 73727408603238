.sider {
    border-right: 1px solid var(--gray-300) !important;
}

.menu {
    color: var(--gray-700);
}

.menu a {
    color: inherit !important;
}

.menuItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.menuBadge > sup {
    background: var(--seventiary-light) !important;
    color: var(--seventiary-dark) !important;
}

.plan-container {
    display: flex;
    flex-direction: row;
    padding: 0 24px;
}
.button-container {
    margin-top: 44px;
    background-color: #F9F5FF;
    padding: 16px;
    border-radius: 10px
}
.button-container > p {
    margin: 0;
    margin-bottom: 20px;
    text-align: center;
}