/**************/
/* FONT FACES */
/**************/

/* RED HAT DISPLAY REGULAR */
@font-face {
    font-family: "RedHatRegular";
    src: local("RedHatRegular"),
        url("../fonts/RedHat/RedHatDisplay-Regular.ttf") format("truetype");
}

/****************/
/* FONT CLASSES */
/****************/

.font-bold {
  font-family: 'RedHatRegular';
  font-weight: 700;
}

.font-medium {
  font-family: 'RedHatRegular';
  font-weight: 500;
}

.font-regular {
  font-family: 'RedHatRegular';
  font-style: normal;
  font-weight: 400;
}