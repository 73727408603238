
:root {
    /**** COLOR VARIABLES ****/
    /* PRIMARY */
    --primary: #8847E6;
    --primary-light: #F2EAFF;
    --primary-ultralight: #F9F5FF;
    --primary-dark: #6A35B7;
    --gradient-primary: linear-gradient(225deg, #8847E6 0%, #6A35B7 100%);
    /* SECONDARY */
    --secondary: #B7EB6F;
    --secondary-light: #EEFFD6;
    --secondary-dark: #A5D663;
    --gradient-secondary: linear-gradient(225deg, #B7EB6F 0%, #A5D663 100%);
    /* TERTIARY */
    --tertiary: #F6F3EC;
    --tertiary-light: #F7F7F7;
    --tertiary-light-2: #FCF9F0;
    --tertiary-dark: #F3EDDE;
    --gradient-tertiary: linear-gradient(224.47deg, #F6F3EC 8.18%, #E7E1D4 95.84%);
    /* FORTHTIARY */
    --forthtiary: #4ACE67; 
    --forthtiary-light: #DAFFE2;
    --forthtiary-dark: #43B65C;
    --gradient-forthtiary: linear-gradient(224.47deg, #4ACE67 8.18%, #43B65C 95.84%);
    /* FIVETIARY */
    --fivetiary: #F85A38;
    --fivetiary-light: #FFDDD6;
    --fivetiary-dark: #CB4A2F;
    --gradient-fivetiary: linear-gradient(224.47deg, #F85A38 8.18%, #CB4A2F 95.84%);
    /* SIXTIARY */
    --sixtiary: #005089;
    --sixtiary-light: #ACCDE4;
    --sixtiary-dark: #013B65;
    --gradient-sixtiary: linear-gradient(224.47deg, #005089 8.18%, #013B65 95.84%);
    /* SEVENTIARY */
    --seventiary: #3AA6F5;
    --seventiary-light: #CCE9FF;
    --seventiary-dark: #0874C1;
    --gradient-seventiary: linear-gradient(224.47deg, #3AA6F5 8.18%, #0873C1 95.84%);
    /* SUCCESS */
    --success: #66CB9F;
    --success-light: #8CDFB3;
    --success-dark: #4AAE8C;
    --gradient-success: linear-gradient(225deg, #67E9F1 0%, #24E795 100%);
    /* WARNING */
    --warning: #F7936F;
    --warning-light: #FAB592;
    --warning-dark: #D46A51;
    --gradient-warning: linear-gradient(225deg, #FFEF5E 0%, #F7936F 100%);
    /* DANGER */
    --danger: #F16063;
    --danger-light: #F68E87;
    --danger-dark: #CF4655;
    --gradient-danger: linear-gradient(225deg, #FFC656 0%, #F16063 100%);
    /* INFO */
    --info: #68DBF2;
    --info-light: #8DEDF7;
    --info-dark: #4CAFD0;
    --gradient-info: linear-gradient(225deg, #68DBF2 0.01%, #509CF5 100%);
    /* DARK */
    --dark: #16192C;
    --dark-light: #505780;
    --dark-dark: #101225;
    --gradient-dark: linear-gradient(225deg, #29272E 0%, #27272E 100%);
    /* GRAY */
    --gray-50: #FAFAFA;
    --gray-100: #F7FAFC;
    --gray-200: #EDF2F7;
    --gray-300: #E2E8F0;
    --gray-400: #CBD5E0;
    --gray-500: #A0AEC0;
    --gray-600: #718096;
    --gray-700: #4A5568;
    --gray-800: #2D3748;
    --gray-900: #1A202C;
    /* PLACEHOLDER */
    --gray-placeholder-light: #B5B5BD;
    --gray-placeholder-dark: #A8A8AE;
    /* MORE GRADIENTS */
    --gradient-conic: conic-gradient(from 193.68deg at 49.94% 50%, #937BDC -65.1deg, #00D87F 46.05deg, #774CF3 265.67deg, #937BDC 294.9deg, #00D87F 406.05deg);
    --gradient-wg: linear-gradient(180deg, #F6F3EC 0%, #B7EB6F 100%);
    --gradient-gp: linear-gradient(180deg, #B7EB6F 0%, #8847E6 100%);
    --gradient-rp: linear-gradient(180deg, #F85A38 0%, #8847E6 100%);
    /**** BUTTON TEXT SIZES ****/
    --text-button-big: 14px;
    --text-button-medium: 12px;
    --text-button-small: 10px;
    /**** SHADOWS ****/
    --shadow-sm: 0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
    --shadow-default: 0px 0px 1px rgba(12, 26, 75, 0.1), 0px 4px 20px -2px rgba(50, 50, 71, 0.08);
    --shadow-lg: 0px 0px 1px rgba(12, 26, 75, 0.1), 0px 10px 16px rgba(20, 37, 63, 0.06);
    --shadow-x1: 0px 0px 1px rgba(12, 26, 75, 0.1), 0px 20px 24px rgba(20, 37, 63, 0.06);
    --shadow-2x1: 0px 0px 1px rgba(12, 26, 75, 0.1), 0px 30px 40px rgba(20, 37, 63, 0.08);
    --shadow-inner: inset 0px 2px 4px rgba(0, 0, 0, 0.06);
    --shadow-input: 0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2);
}