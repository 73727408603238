.quali-title {
  font-size: 20px;
  font-weight: 600;
  color: #8847e6;
  font-style: normal;
  margin-top: 38px;
}

.quali-percent-text {
  font-weight: 500;
  font-size: 15px;
  text-align: center;
}

.quali-container {
  margin-top: 50px;
  margin-bottom: 56px;
}

.quali-option {
  margin: 0;
  margin-top: 10px;
}

.container-answers {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.answer-button {
  width: 100%;
  height: 50px;
  border-radius: 6px;
  margin-bottom: 20px;
  text-align: left;
  padding-left: 23px;
  font-size: 15px;
}

.legal {
  margin-top: 10px;
}

.legal p {
  font-size: 13px;
}

.legal a {
  color: #8847e6;
}

.preview-input {
  pointer-events: none;
  border-radius: 6px;
  padding: 11px 50px 13px 16px;
}

.preview-input:hover {
  border: 1px solid #8847E6;
  background: #FFFFFF;
  box-shadow: 0px 0px 0px 3.5px rgba(136, 71, 230, 0.25);
}

.preview-dropdown {
  pointer-events: none;
  border-radius: 6px;
  padding: 24px 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #a9a9a9;
}

.preview-dropdown .dropdown-text {
  margin-bottom: 0;
}
