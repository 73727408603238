.header {
    background: var(--tertiary-light-2) !important;
    border-bottom: 1px solid var(--gray-300);
    height: auto !important;
    line-height: normal !important;
    padding: 20px !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.headerTitle {
    color: black;
    font-size: 28px;
}

.avatarBell {
    background-color: var(--gray-200) !important;
}

.badgeBell {
    margin-right: 20px !important;
}

.badgeBell sup {
    width: 12px !important;
    min-width: 12px !important;
    height: 12px !important;
    background: var(--primary) !important;
    box-shadow: 0 0 0 3px #fff !important;
}
.menuUsername {
    font-weight: bold;
}

.menuItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  background: none;
  border: none;
  padding: 0;
}

.menuIcon {
    margin-right: 10px
}

.headerActionSection {
    margin-right: 8px;
    display: flex;
}

.profileButton {
    margin-top: -4px;
}

.trialInfoMessage {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2eaff;
    height: 40px;
    padding: 0 16px;
    margin-right: 24px;
    border-radius: 8px;
    border: none;
    font-size: 12px;
    color: #8847e6;
    font-style: normal;
    font-weight: 700;
}
