.checkbox {
  position: relative;
}
.checkbox span {
  background-color: white;
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: 3px;
  left: 3px;
}
.checkbox label {
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20px;
}

.checkbox label:after {
  content: "";
  height: 6px;
  width: 12px;
  width: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
}
.checkbox input[type="checkbox"] {
  visibility: hidden;
}
.checkbox input[type="checkbox"]:checked + label {
  border-color: #8847e6;
  padding: 2px;
}
.checkbox input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
.checkbox input[type="checkbox"]:checked + label > span {
  background-color: #8847e6;
}
.checkbox input[type="checkbox"]:checked + label:after > span:after {
  opacity: 1;
}
