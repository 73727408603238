.no-form-container {
  background-color: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-top: 164px;
}
.no-form-image {
  width: 70px;
  height: 70px;
}
.no-form-title {
  font-size: 26px;
  font-weight: 600;
  line-height: 39px;
  color: #1a202c;
}
.no-form-text {
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  color: #4a5568;
}
.no-form-button {
  border: none;
  width: 141px;
  height: 50px;
  padding: 10px 18px;
  border-radius: 8px;
  margin-top: 30px;
  color: #fff;
  background-color: #8847e6;
  font-size: 16px;
  font-weight: 700;
  line-height: 14px;
  cursor: pointer;
}

.forms-button-container {
  margin-bottom: 30px;
}

.forms-button {
  width: 120px;
  height: 38px;
  background-color: #8847e6;
  border: none;
  border-radius: 6px;
  padding: 12px 20px;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px;
  color: #fff;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.forms-button:hover {
  cursor: pointer;
  background-color: #f2eaff;
  color: #8847e6;
}

.copyTextarea {
  color: #718096;
  padding: 8px;
  width: 100%;
  border-radius: 16px;
  border: 0.5px solid var(--gray-100, #f7fafc);
  box-shadow: 0px 0px 16px 0px rgba(20, 37, 63, 0.06),
    0px 0px 1px 0px rgba(12, 26, 75, 0.1);
  margin-bottom: 8px;
}

.formCard {
  display: flex;
  flex-wrap: wrap;
}
