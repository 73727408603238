@import url("./assets/styles/fonts.css");
@import url("./assets/styles/colors.css");
@import url("./assets/styles/variables.css");
@import url("./assets/styles/buttons.css");
@import url("./assets/styles/typography.css");
@import url("./assets/styles/containers.css");
@import url("./assets/styles/modifiers.css");
@import url("antd/dist/antd");

.main-container {
  padding: 30px;
}

.weyzo-loader-page-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75vh;
}

.weyzo-loader-global-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.ant-modal-content {
  border-radius: 16px;
  font-family: "RedHatRegular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-modal-header {
  border-radius: 16px 16px 0 0;
}

.ant-modal-content .ant-modal-title {
  font-size: 20px;
}

.ant-modal-content .ant-btn {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  height: 42px;
  padding: 10px 18px;
}

.wselect .ant-select-selector {
  border-radius: 6px;
  color: var(--gray-700);
  font-size: 15px;
  border: 2px solid transparent;
  cursor: pointer !important;
}

.wselect .ant-select-selector input {
  cursor: pointer !important;
}