.upgrade {
    font-family: 'RedHatRegular';
    width: 100%;
    padding-bottom: 25px
}
.back {
    margin-top: 36px;
    margin-left: 24px
}
.arrow {
    margin-right: 20px
}

.page-header-container {
    margin-top: 34px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.page-header-container h1 {
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: 52px;
    color: #1A202C
}
.page-header-container h3 {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    color: #4A5568
}

.plans-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 78px 155px 0 155px
}

.plan-container {
    border-radius: 20px;
    box-shadow: 0px 3px 20px 6px rgba(50, 50, 71, 0.10), 0px 0px 1px 0px rgba(12, 26, 75, 0.24);
    padding: 32px 24px
}
.plan-pro {
    background-color: #8847E6;
    margin: 0 24px;
}

/* PLAN HEADER STARTER + ENTERPRISE */
.plan-header-container {
    margin-bottom: 32px
}
.plan-header-containter p {
    margin: 0
}
.plan-header-plan {
    color: #8847E6;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    margin-bottom: 14px
}
.plan-header-price {
    color: #1A202C;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: 52px;
    margin-bottom: 8px
}
.plan-header-time {
    color: #A0AEC0;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
}

/* PLAN HEADER PRO */
.plan-pro-header {
    margin-top: -36px
}
.plan-pro-plan {
    color: #fff;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    margin-bottom: 14px
}
.plan-pro-price {
    color: #fff;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: 52px;
    margin-bottom: 8px
}
.plan-pro-time {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
}

/* PRO WORKSPACE + FEATURES */
.feat-pro-container {
    margin-top: 32px
}
.feat-pro-container h3 {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    margin-bottom: 24px
}


/* WORKSPACE + FEATURES */
.feat-container {
    margin-top: 32px
}
.feat-container h3 {
    color: #8847E6;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    margin-bottom: 24px
}

/* LIST ITEMS WORKSPACE + FEATURES */

.list-item-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px
}
.list-item-container p {
    margin: 0 0 0 14px;
    color: #2D3748;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
}
.no-check {
    color: #A0AEC0 !important
}

.list-item-pro-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px
}
.list-item-pro-container p {
    margin: 0 0 0 14px;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
}
.no-check-pro {
    color: #6A35B7 !important
}

.popular {
    background-color: #1A202C;
    width: 136px;
    height: 36px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 100px;
    padding: 12px 20px;
    top: -50px;
    left: 150px
}
.popular p {
    margin: 0;
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px;
}
.popular img {
    width: 12px;
    height: 12px;
    margin-left: 8px
}