.upgrade-container {
  display: flex;
  flex-direction: row;
}

.upgrade-text {
  font-size: 12px;
  color: #8847e6;
  font-style: normal;
  font-weight: 700;
  line-height: 12px;
  margin-right: 8px;
}

.upgrade-icon {
  width: 12px;
  height: 12px;
}

.upgrade-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2eaff;
  height: 40px;
  padding: 0 16px;
  margin-right: 24px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
}

/* PLANS */
.plan-button {
  width: 100%;
  height: 50px;
  border-radius: 8px;
  border: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
}

.plan-button:not([disabled]) {
  cursor: pointer;
}

.plan-starter {
  background-color: #f2eaff;
  color: #8847e6;
}

.plan-pro {
  color: #8847e6;
}

.plan-enterprise {
  background-color: #8847e6;
  color: #fff;
}

.upgrade-modal {
  border-radius: 8px;
  width: 178px;
  height: 50px;
  padding: 10px 18px;
  border: none;
  background-color: #8847e6;
  cursor: pointer;
}

.upgrade-modal:hover {
  background-color: #6938ae;
}

.upgrade-modal p {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  color: #fff;
  margin: 0;
}

.upgrade-leads {
  border-radius: 6px;
  height: 36px;
  padding: 12px 20px;
  border: none;
  background-color: var(--primary);
  cursor: pointer;
}

.upgrade-leads p {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  color: #fff;
  margin: 0;
}
