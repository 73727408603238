.modal-container {
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 8;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.modal {
  position: absolute;
  z-index: 10;
  background-color: #fff;
  width: 500px;
  height: auto;
  padding: 24px;
  border-radius: 16px;
}

.flex-buttons {
  display: flex;
  justify-content: flex-end;
}

.primary-button {
  color: #8847E6;
}
