body {
  font-family: 'RedHatRegular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-menu-inline .ant-menu-item::after {
  left: 0 !important;
  right: auto !important;
  border-right: 3px solid var(--primary) !important;
}

.ant-menu-inline.ant-menu-root .ant-menu-item {
  background-color: white !important;
}

.ant-menu-item-selected {
  color: var(--primary) !important;
}

.ant-menu-light .ant-menu-item:hover {
  color: var(--primary) !important;
}

/* ANTD TABLES */
.ant-table-thead .ant-table-cell {
  background-color: transparent !important;
  text-transform: uppercase;
  font-family: 'RedHatRegular';
  font-weight: 700;
  font-size: 11px;
  color: var(--gray-700);
}

.ant-table-tbody {
  font-family: 'RedHatRegular';
  font-size: 14px;
  color: var(--gray-700);
}

.ant-table-cell-row-hover {
  background: var(--tertiary-light-2) !important;
}

/* ANTD PAGINATION */
.ant-pagination-item {
  border: none !important;
  border-radius: 100%;
}

.ant-pagination-item-active {
  border-radius: 100% !important;
  background-color: var(--primary-light) !important;
}

.ant-pagination-item-active a {
  color: var(--primary) !important;
}

.ant-pagination-disabled a {
  color: var(--gray-placeholder-dark) !important;
}

.ant-pagination-prev a {
  color: var(--gray-700);
}

.ant-pagination-next a {
  color: var(--gray-700);
}

.ant-pagination-prev a:hover {
  color: inherit !important;
}

.ant-pagination-next a:hover {
  color: inherit !important;
}

.ant-input-affix-wrapper {
  border: 2px solid transparent !important;
  border-radius: 6px !important;
}

.ant-input-affix-wrapper-focused {
  border: 2px solid var(--secondary-dark) !important;
  box-shadow: var(--shadow-sm) !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--primary) !important;
}

.ant-tabs-tab:hover {
  color: var(--primary-dark) !important;
}

.ant-switch-checked {
  background: var(--forthtiary) !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: var(--shadow-input) !important;
  border-radius: 6px !important;
  color: var(--gray-700) !important;
  font-size: 15px !important;
  border: 2px solid transparent !important;
}

.ant-picker-input > input {
  text-align: center;
  background: var(--primary-ultralight) !important;
  border-radius: 5px !important;
  padding: 5px 10px !important;
}

.ant-picker-range-separator {
  padding: 0 13px !important;
}

.ant-picker-range-separator > span {
  display: none;
}

.ant-picker-range-separator::after {
  content: 'to';
}

.ant-tabs-ink-bar {
  background-color: var(--primary) !important;
}
