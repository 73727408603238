.container {
  background-color: white;
  width: 50%;
  padding: 0 5% 59px 5%;
  margin-left: 0;
  margin-top: 0;
  min-height: 100vh;
}

.input {
  border-top: none;
  border-left: none;
  border-right: none;
}

.buttons-container {
  display: flex;
  flex-direction: row;
  margin-top: 65px;
  justify-content: space-between;
}

.button-draft {
  background-color: white;
  border: 2px solid #4a5568;
  font-weight: 700;
  border-radius: 8px;
  height: 46px;
  width: 47%;
  color: #4a5568;
}

.button-draft:hover {
  background-color: #4a5568;
  color: white;
  cursor: pointer;
}

.button {
  background-color: #4a5568;
  border-radius: 8px;
  width: 47%;
  height: 46px;
  color: white;
  font-weight: 700;
  border: none;
}

.button:hover {
  background-color: white;
  color: #4a5568;
  border: 2px solid #4a5568;
  cursor: pointer;
}

.button-disabled {
  pointer-events: none;
}

.preview {
  position: relative;
  min-width: 485px;
  margin-left: 5%;
  background-color: white;
  border-radius: 10px;
  padding: 60px;
  min-height: 550px;
  margin-top: 32px;
  margin-bottom: 32px;
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
    0px 3px 20px 6px rgba(50, 50, 71, 0.1);
  display: flex;
  flex-direction: column;
  align-content: center;
}

.logoContainer img {
  height: 39px;
  width: auto;
  object-fit: contain;
}

.preview-placeholder {
  color: #c1c1c1;
}

.preview-normal {
  color: black;
}

.preview-title {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 8px;
  text-align: center;
}

.preview-subtitle {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.preview-q-title {
  margin-top: 16px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 7px !important;
}

.preview-button {
  height: 46px;
  margin-top: 30px;
  color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.preview-button p {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0;
}

.main {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  font-family: "RedHatRegular";
  padding-right: 32px;
}

.collapse {
  background-color: #f7fafc;
  border-left: none;
  border-right: none;
  margin-top: 40px;
}

.ant-collapse-item {
  height: 65px !important;
}

.collapse-panel > div {
  flex-direction: row-reverse;
}

.collapse-panel > div > span {
  font-weight: 600;
  color: #4a5568;
}

.logoContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 30px;
}

.top-menu-container {
  padding: 32px 27px 24px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.top-menu-left {
  display: flex;
  flex-direction: row;
  align-content: center;
}

.top-menu-left-text {
  display: flex;
  flex-direction: row;
  margin-left: 30px;
  align-items: center;
}

.top-menu-left-text:hover {
  cursor: pointer;
}

.top-menu-left-text p {
  margin: 0;
  font-size: 16px;
  margin-left: 15px;
  font-weight: 600;
  color: #4a5568;
}

.top-menu-left-text span {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.top-menu-right img:hover {
  color: #1a202c;
  cursor: pointer;
}

.logo-weyzo {
  height: 26px;
  width: auto;
  object-fit: contain;
}

.quali-title {
  font-size: 20px;
  font-weight: 600;
  color: #8847e6;
  font-style: normal;
  margin-top: 38px;
}

.quali-percent-text {
  font-weight: 500;
  font-size: 15px;
  text-align: center;
}

.quali-container {
  margin-top: 50px;
}

.quali-option {
  margin: 0;
  margin-top: 10px;
}

.container-answers {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.answer-button {
  height: 50px;
  border-radius: 6px;
  margin-bottom: 20px;
  text-align: left;
  padding-left: 23px;
  font-size: 15px;
}

.forms-auto-save-loading {
  display: flex;
  margin-left: 16px;
}

.forms-auto-save-loading div {
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.forms-auto-save-loading img:first-child {
  width: 25px;
}

.forms-auto-save-loading img:nth-child(2) {
  width: 25px;
  left: -20px;
}

.input_error {
  border: 2px solid var(--danger-dark);
}

.input_error:focus {
  outline: none;
  box-shadow: none;
  border: 2px solid var(--danger-dark);
}

.input_error_message {
  color: var(--danger-dark);
  margin-bottom: 8px;
}
