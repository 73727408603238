.upload-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.upload {
    display: none
}
.upload-button {
    color: #8847E6;
    background-color: white;
    border: solid 1px #8847E6;
    border-radius: 8px;
    height: 40px;
    width: 10rem;
    font-weight: 700;
    font-size: 12px;
    margin-left: 50px;
    cursor: pointer;
}
.upload-button:hover {
    background-color: rgba(0, 0, 0, 0.05);
}
.upload-preview {
    height: 100px;
    border-radius: 8px;
    box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
    min-width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.upload-preview img {
    height: 30px;
    width: auto;
    object-fit: contain;
}

@media (max-width: 1200px) {
    .upload-preview {
        min-width: 150px
    }
}
@media (max-width: 940px) {
    .upload-button {
        width: 82%
    }
}
@media (max-width: 700px) {
    .upload-button {
        width: 50%;
        margin-left: 20px
    }
}
/* @media (max-width: 800px) {
    .upload-button {
        widt
    }
} */