.modal-container {
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 8;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.modal {
  position: absolute;
  z-index: 10;
  background-color: #fff;
  width: 700px;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
}

.modal-close {
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
}

.modal-light {
  margin-bottom: 25px;
}

.modal h2 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  margin-bottom: 25px;
}

.modal-text {
  margin: 0;
  width: 438px;
  height: 48px;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.09px;
  margin-bottom: 25px;
}
