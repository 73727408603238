.isFlex {
  display: flex;
  justify-content: space-between;
}

.topActionButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px
}

.trashButton {
  cursor: pointer;
}

.trashButton:hover {
  filter: brightness(0) saturate(100%) invert(52%) sepia(21%) saturate(3336%) hue-rotate(319deg) brightness(93%) contrast(104%);
}

.eyeButton {
  margin-right: 20px;
  cursor: pointer;
}

.eyeButton:hover {
  filter: brightness(0) saturate(100%) invert(41%) sepia(55%) saturate(7405%) hue-rotate(251deg) brightness(95%) contrast(90%);
}

.formCardItem {
  flex: 1;
  margin-right: 30px;
  margin-bottom: 30px;
  min-width: 350px;
  max-width: 350px;
}
