.Winput {
    box-shadow: var(--shadow-input) !important;
    border-radius: 6px; 
    color: var(--gray-700); 
    font-size: 15px;
    border: 2px solid transparent !important;
}

.Winput:focus {
    border: 2px solid var(--secondary-dark) !important;
    box-shadow: var(--shadow-sm) !important;
}

.Winput:disabled {
    background-color: #f5f5f5; /* Lighter background color */
    color: var(--gray-700); /* Darker text color */
    cursor: not-allowed; /* Change cursor to indicate disabled state */
}
