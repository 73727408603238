.container-lg {
  box-shadow: var(--shadow-lg);
  padding: 20px;
  background-color: white;
  border-radius: 8px;
}

.container-card {
  box-shadow: var(--shadow-sm);
  border-radius: 8px;
  padding: 20px;
}

.container-clickable-card {
  box-shadow: var(--shadow-sm);
  border-radius: 8px;
  padding: 20px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.container-clickable-card:hover {
  background: var(--tertiary-light-2);
}
