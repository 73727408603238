.check {
  width: 16px;
  height: 16px;
}
.container {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px;
}
.check-container {
  background-color: #f2eaff;
}
.no-check-container {
  background-color: #f7fafc;
}
