.row {
  display: flex;
  flex-direction: row;
}

.between {
  justify-content: space-between;
  width: 100%;
}

.container-color-picker {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  padding-top: 29px;
}

.container-single-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
}

.single-input {
  height: 60px;
  padding: 20px 16px;
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
    0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  border-radius: 8px;
}

.container-single-input p {
  margin-bottom: 0;
}

.required {
  margin-right: 10px;
}

.fixed-question {
  margin-left: 14px;
  font-weight: 600;
  line-height: 22px;
  color: #1a202c;
}

.container-single-input-group {
  display: flex;
  flex-direction: row;
}

.qualifying-panel {
  margin: 14px 0 30px 0;
  padding: 0 40px;
}

.qualifying-panel p {
  color: #4a5568;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
}

.container-add-question {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 36px;
  margin-left: 43px;
  color: #b9b9b9;
  cursor: pointer;
}

.container-add-question p {
  margin-bottom: 0;
  margin-left: 21px;
}

.fixed-input {
  margin-top: 19px;
}

.container-qualy-input-card {
  width: 100%;
  border: 1px solid #d2d2d2;
  margin-top: 22px;
  padding-right: 28px;
  padding-left: 20px;
  padding-bottom: 34px;
  padding-top: 20px;
  border-radius: 10px;
}

.qualy-switch {
  margin-left: 42px;
}

.icon-close {
  margin-left: 19px;
  color: #b5b5bd;
}

.icon-holder {
  margin-right: 14px;
  font-size: 20px;
}

.icon-hor {
  transform: rotate(90deg);
}

.container-qualy-title {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.container-qualy-title p {
  margin-bottom: 0;
}

.container-icons {
  top: 25px;
  margin-left: 13px;
  color: #a8a8ae;
}

.container-icons p {
  color: #718096;
  font-size: 10px;
}

.container-answers {
  margin-top: 43px;
}

.container-scoring {
  border-top: 1px solid #ececec;
  margin-top: 33px;
  padding-top: 19px;
}

.h3 {
  font-size: 18px;
  color: #4a5568;
  border-bottom: 1px solid #ececec;
  padding-bottom: 17px;
}

.border-dashed {
  padding: 30px;
}

.border-dashed p {
  margin: 0;
}

.total-score {
  background-color: #f6f6f6;
  margin-top: 20px;
  padding: 17px 140px;
  color: #718096;
}

.total-score p {
  margin: 0;
}

.total-score span {
  font-size: 15px;
  margin-left: 20px;
}

.minimum-score {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}

.minimum-score p {
  margin: 0;
  font-weight: 600;
  margin-left: 75px;
}

.minimum-score > div {
  margin-right: 75px;
}

.embed-container {
  margin-top: 15px;
  border-top: 1px solid #ececec;
  padding-top: 19px;
}

.radio-container {
  margin-top: 32px;
}

.radio-container label {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.radio-container span {
  top: 0;
}

.radio {
  height: 125px;
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 8px;
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
    0px 3px 8px -1px rgba(50, 50, 71, 0.05);
}

.radio-margin {
  margin-bottom: 16px;
}

.radio-text {
  margin-left: 30px;
}

.radio p {
  margin: 0;
}

.radio b {
  margin-bottom: 10px !important;
}

.radio-text-grey {
  color: #a0aec0;
}

.radio-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sortable-ans-container {
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
    0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  padding: 10px;
  align-items: center;
  background-color: white;
  border-radius: 8px;
}

.sortable-ans-container input {
  margin-right: 15px;
}

.sortable-ans-container p {
  margin-right: 10px;
}

.customQuestionsPanel {
  margin-top: 16px;
}

.custom-box {
  border: 2px dashed #a0aec0;
  padding: 25px 100px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.custom-box p {
  font-size: 14px;
  margin: 0;
  line-height: 22px;
  font-weight: 600;
  color: #718096;
}

.custom-title {
  margin-top: 33px;
  margin-bottom: 15px;
}

.custom-title p {
  margin: 0;
  margin-left: 25px;
  font-size: 14px;
}

.custom-question {
  border-radius: 8px;
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
    0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  width: 474px;
  height: 60px;
  padding: 10px 20px 10px 10px;
  align-items: center;
}

.custom-question p {
  margin: 0;
}

.custom-question input {
  width: 100%;
  margin-left: 15px;
  margin-right: 15px;
}

.custom-select {
  min-width: 120px;
}

.custom-required {
  align-items: center;
}

.custom-required p {
  margin-right: 10px;
}

.custom-all {
  align-items: center;
  margin-bottom: 15px;
}

.addNewCustomQuestionRow {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 16px;
}

.addNewCustomQuestionText {
  color: #1a202c;
  font-size: 14px;
  font-weight: 600;
  margin-left: 8px;
  margin-bottom: 0;
}

.addNewCustomQuestionIcon {
  color: #40444a;
}

.addNewCustomQuestionIcon:hover {
  cursor: pointer;
  color: #8847E6;
}

.addNewCustomQuestionText:hover {
  cursor: pointer;
  color: #8847E6;
}

.qualy-questions {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}

.panel-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
}

.panel-icon p {
  margin: 0;
  margin-left: 20px;
  font-size: 16px;
}

.input-label-text {
  font-weight: 700;
  color: #4a5568;
}

.watermark {
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
}

.watermark span {
  font-weight: 600;
  margin-right: 16px;
}

.watermarkLogo {
  width: 100px;
}

.watermarkSwitchContainer {
  display: flex;
  align-items: center;
  margin-top: 32px;
}

.watermarkSwitchText {
  margin-right: 16px;
  font-weight: 600;
}

.watermarkDisabledInfo {
  margin-left: 20px;
}

.Input {
  box-shadow: var(--shadow-input) !important;
  border-radius: 6px;
  color: var(--gray-700);
  font-size: 15px;
  border: 2px solid transparent !important;
  height: 40px;
}

.Input:focus {
  border: 2px solid var(--secondary-dark) !important;
  box-shadow: var(--shadow-sm) !important;
}
