.title-purple {
    font-family: 'RedHatRegular';
    color: var(--primary);
    font-size: 20px;
}

.big-text {
    font-size: 40px;
    font-family: 'RedHatRegular';
    color: var(--gray-700);
}

.medium-text {
    font-size: 28px;
    font-family: 'RedHatRegular';
    color: var(--gray-700);
}

.default-text {
    font-size: 14px;
    font-family: 'RedHatRegular';
    color: var(--gray-700);
}