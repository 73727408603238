.container-upcoming-meetings {
  padding: 15px;
  background-color: #f6f3ec;
  border-radius: 8px;
}

.container-upcoming-meetings table {
  border-collapse: separate;
  border-spacing: 0 1.4em;
  background: #f6f3ec;
}

.container-upcoming-meetings thead {
  background-color: #f6f3ec;
}

.container-upcoming-meetings
  thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  display: none;
}

.container-upcoming-meetings thead th {
  text-align: center;
  border: none;
}

.container-upcoming-meetings thead > tr {
  box-shadow: none;
}

.container-upcoming-meetings tr {
  box-shadow: var(--shadow-sm);
  border-radius: 8px;
}

.container-upcoming-meetings td {
  text-align: center;
  background: white;
  border: none !important;
}

.container-upcoming-meetings td:first-of-type {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.container-upcoming-meetings td:last-of-type {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.container-upcoming-meetings thead th:first-of-type {
  text-align: left;
}

.container-upcoming-meetings td:not(:last-of-type)::before {
  content: "";
  background-color: var(--gray-300);
  height: 1rem;
  width: 1px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}

.card-dashboard {
  background: white;
  text-align: center;
}

.container-filters {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.blur {
  filter: blur(3px);
  cursor: pointer;
}
