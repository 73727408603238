.primary-btn {
  background: var(--primary) !important;
  border-radius: 6px !important;
  color: white !important;
  border: none !important;
}

.primary-btn:hover {
  background: var(--primary-dark) !important;
  box-shadow: var(--shadow-inner) !important;
}

.primary-btn-outline {
  border: 1px solid var(--primary) !important;
  color: var(--primary) !important;
  border-radius: 6px !important;
  background: #FFFFFF !important;
}

.primary-btn-outline:hover {
  box-shadow: var(--shadow-inner) !important;
}

.primary-btn-outline-round {
  border: 1px solid var(--primary) !important;
  color: var(--primary) !important;
  border-radius: 60px !important;
  background: #FFFFFF !important;
}

.primary-btn-outline-round:hover {
  box-shadow: var(--shadow-inner) !important;
}

.full-btn {
  width: 100%;
}

.primary-btn-rounded {
  background: var(--primary) !important;
  border-radius: 60px !important;
  color: white !important;
  border: none !important;
}

.primary-btn-rounded:hover {
  background: var(--primary-light) !important;
  color: var(--primary) !important;
  box-shadow: var(--shadow-inner) !important;
}

.danger-btn-rounded {
  background: var(--danger) !important;
  border-radius: 60px !important;
  color: white !important;
  border: none !important;
}

.danger-btn-rounded:hover {
  background: var(--danger-light) !important;
  color: var(--danger) !important;
  box-shadow: var(--shadow-inner) !important;
}

.primary-btn-light {
  background: var(--primary-light) !important;
  border-radius: 6px !important;
  color: var(--primary) !important;
  border: none !important;
}

.primary-btn-light:hover {
  background: var(--primary) !important;
  color: var(--primary-light) !important;
  box-shadow: var(--shadow-inner) !important;
}

.tertiary-btn {
  background: var(--tertiary) !important;
  border-radius: 6px !important;
  color: black !important;
  border: none !important;
}

.tertiary-btn:hover {
  background: var(--tertiary-dark) !important;
  box-shadow: var(--shadow-inner) !important;
}
