.containerPicker {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 5px;
    padding-left: 7px;
    padding-bottom: 6px;
    margin-right: 28px;
    flex-wrap: nowrap;
}
.color {
    width: 38px;
    height: 40px;
    margin-right: 11px;
    border-radius: 5px;
}
.hex {
    height: 40px;
    min-width: 180px;
    max-width: 200px;
    box-shadow: 0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2);
    padding-top: 12px;
    padding-left: 16px;
    border-radius: 6px;
    margin-right: 10px
}