.containerForm {
  width: 450px;
  margin-top: 30px;
}

@media only screen and (max-width: 768px) {
  .containerForm {
    width: 300px;
    margin-top: 30px;
  }
}
